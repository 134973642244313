<template>
  <div class="mt-5">
    <public-jobs-display />
  </div>
</template>

<script>
import PublicJobsDisplay from "@core/components/shared/jobs/PublicJobsDisplay.vue";

export default {
  components: {
    PublicJobsDisplay
  },
}
</script>

